import request from '@/utils/request'

const tradeInventoryAdjustmentApi = {
  trade_inventory_adjustment_list: '/trade/inventory_adjustment/',
  trade_inventory_adjustment_create: '/trade/inventory_adjustment/',
  trade_inventory_adjustment_update: '/trade/inventory_adjustment/',
  trade_inventory_adjustment_delete: '/trade/inventory_adjustment/',
  trade_inventory_adjustment_archived: '/trade/inventory_adjustment_archived/'
}

/**
 * 列表
 */
export function inventory_adjustment_list (parameter) {
  return request({
    url: tradeInventoryAdjustmentApi.trade_inventory_adjustment_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function inventory_adjustment_create (parameter) {
  return request({
    url: tradeInventoryAdjustmentApi.trade_inventory_adjustment_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function inventory_adjustment_update (parameter, inventory_adjustment_id) {
  return request({
    url: tradeInventoryAdjustmentApi.trade_inventory_adjustment_update + inventory_adjustment_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function inventory_adjustment_delete (inventory_adjustment_id) {
  return request({
    url: tradeInventoryAdjustmentApi.trade_inventory_adjustment_delete + inventory_adjustment_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function inventory_adjustment_archived (parameter) {
  return request({
    url: tradeInventoryAdjustmentApi.trade_inventory_adjustment_archived,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
