import request from '@/utils/request'

const tradeWarehouseApi = {
  trade_warehouse_list: '/trade/warehouse/',
  trade_warehouse_create: '/trade/warehouse/',
  trade_warehouse_update: '/trade/warehouse/',
  trade_warehouse_delete: '/trade/warehouse/'
}

/**
 * 列表
 */
export function warehouse_list (parameter) {
  return request({
    url: tradeWarehouseApi.trade_warehouse_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function warehouse_create (parameter) {
  return request({
    url: tradeWarehouseApi.trade_warehouse_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function warehouse_update (parameter, warehouse_id) {
  return request({
    url: tradeWarehouseApi.trade_warehouse_update + warehouse_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function warehouse_delete (warehouse_id) {
  return request({
    url: tradeWarehouseApi.trade_warehouse_delete + warehouse_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
